// @flow
import React from "react";
import { css } from "aphrodite";
import styles from "./TestimonialSecStyles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Images } from "../../../theme";
import { TESTIMONIALS } from "../../../constants";

export default class TestimonialSecView extends React.Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    var settings = {
      dots: false,
      arrows: false,
      autoPlay: true,
      infinite: true,
      lazyLoad: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="container my-5">
        <h3 className={css(styles.headFAQ)}>Frequently asked questions</h3>
        <h3 className={css(styles.faqSt)}>
          <p style={{ fontWeight: "bold", marginBottom: 5, marginTop: 5 }}>
            What Is Cognitive Behavior Therapy (CBT)?
          </p>
          CBT is an effective type of psychological therapy used to treat a
          range of common mental health problems, such as depression and
          anxiety.
          <p style={{ fontWeight: "bold", marginBottom: 5, marginTop: 5 }}>
            How long does CBT typically last?
          </p>
          CBT usually lasts between 5 to 20 sessions, depending on individual
          needs and treatment goals. Each session typically lasts around 50
          minutes.
          <p style={{ fontWeight: "bold", marginBottom: 5, marginTop: 5 }}>
            What can I expect during my first session?
          </p>
          During your first session, the therapist will assess your needs,
          discuss treatment goals, and explain how CBT works. You’ll also have
          an opportunity to ask questions.
          <p style={{ fontWeight: "bold", marginBottom: 5, marginTop: 5 }}>
            Is CBT suitable for everyone?{" "}
          </p>
          CBT is effective for many individuals experiencing anxiety,
          depression, and other mental health issues. However, it's essential to
          consult with a therapist to determine suitability.
          <p style={{ fontWeight: "bold", marginBottom: 5, marginTop: 5 }}>
            What are some common techniques used in Cognitive Behavioral
            Therapy?
          </p>
          Common techniques in CBT include cognitive restructuring, exposure
          therapy, and behavioral activation. These methods help individuals
          identify negative thought patterns and develop healthier behaviors.
          <p style={{ fontWeight: "bold", marginBottom: 5, marginTop: 5 }}>
            How can I find Cognitive Behavioral Therapy near me?
          </p>
          To find CBT near you, search online directories or local mental health
          clinics. You can also ask your healthcare provider for recommendations
          to find qualified therapists.
          <p style={{ fontWeight: "bold", marginBottom: 5, marginTop: 5 }}>
            What is involved in CBT training?{" "}
          </p>
          CBT training typically involves learning various therapeutic
          techniques, understanding cognitive processes, and gaining practical
          skills through supervised practice, enabling mental health
          professionals to effectively apply CBT with clients.
        </h3>
        <h2 className={css(styles.headingFont)}>
          What Our Clients Say About Us
        </h2>
        <Slider ref={(c) => (this.slider = c)} {...settings}>
          {TESTIMONIALS.map((item) => {
            return (
              <div key={item.id} className={css(styles.testimonialBox)}>
                <span className={css(styles.boxContent)}>
                  {item.text}
                  <span
                    style={{
                      fontWeight: 600,
                      display: "block",
                      textAlign: "center",
                    }}
                  >
                    {item.reivewedBy}
                  </span>
                </span>
              </div>
            );
          })}
        </Slider>
        <div className={css(styles.prevNextContainer)}>
          <button className={css(styles.prevBtn)} onClick={this.previous}>
            <img src={Images.prev_arrow} className={css(styles.arrowIcon)} />
          </button>
          <button onClick={this.next} className={css(styles.nextBtn)}>
            <img src={Images.next_arrow} className={css(styles.arrowIcon)} />
          </button>
        </div>
      </div>
    );
  }
}
