// @flow
import React from "react";
import { css } from "aphrodite";
import { Images, AppStyles } from "../../../theme";
import styles from "./TeamSecStyles";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { Button } from "react-bootstrap";
import { OurTeamSection } from "../../../components";
export default function TeamSecView(props) {
  return (
    <section
      className={`py-5 ${css([
        // styles.marginBottom,
        styles.TeamSec,
        // AppStyles.pxy_12,
      ])}`}
    >
      <div className={`container`}>
        <h2
          className={`mt-5 ${css(styles.heading)}`}
          style={{ marginBottom: "20px" }}
        >
          What We Offer for CBT
        </h2>

        <div className={css(styles.cbtexample)}>
          At Health City, we provide both in-person and online Cognitive Behavioral
          Therapy (CBT) appointments. We specialize in treating a wide range of
          mental health conditions, including:
          <p className={css(styles.listSt)}>
            <br />
            – Depression
            <br />
            – Health Anxiety
            <br />
            – Specific Phobias
            <br />
            – Panic
            <br />
            – Generalised Anxiety Disorder
            <br />
            – Social Anxiety
            <br />
            – Obsessive Compulsive Disorder (OCD)
            <br />
            – Post Traumatic Stress Disorder (PTSD)
            <br />– And More…
            <br />
          </p>
          Our evidence-based therapy is designed to help individuals manage
          their thoughts and behaviors for improved emotional well-being. We
          also offer support for many other mental health issues to ensure
          personalized care for every individual.
        </div>

        <div className={css(styles.teamContainer)}>
          <div className={`mt-5 ${css(styles.teamHead)}`}>Meet The Team</div>
          <div className={css(styles.cardBox)} style={{ marginTop: "20px" }}>
            <div className={css(styles.cardContent)}>
              Healthcity’s core team and its associates include qualified
              psychologists, therapists, nurses, doctors and other
              professionals, as well as, former service users. They have
              relevant training, skills, and experiences culturally and
              professionally.Below just some of our team members. Behind the
              experienced directors and managers below stand a number of
              awesome, dedicated non-executive directors and advisors,
              clinicians, University researchers and client support staff who
              make us all look good and achieving.
            </div>
          </div>
        </div>
        <div className={css(styles.teamSectionView)}>
          <OurTeamSection isShortView={true} />
        </div>
        <div className={css(styles.teamBtnCont)}>
          <NavLink to={ROUTES.TEAM}>
            <Button
              className={css(styles.teamBtn)}
              style={{ marginTop: "-40px" }}
            >
              Check All Team
            </Button>
          </NavLink>
        </div>
      </div>
    </section>
  );
}
